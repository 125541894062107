import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useWebSocket, { ReadyState } from "react-use-websocket";
import LogoutIcon from "@mui/icons-material/Logout";

const AdcCard = ({ rValue, yValue, bValue, ryb, pump }) => {
  let [firstDigit, secondDigit, thirdDigit] = "000";
  if (ryb) {
    [firstDigit, secondDigit, thirdDigit] = ryb.split("").map(Number);
  }
  return (
    <Card sx={{ minWidth: 600, borderRadius: 2 }} variant="outlined">
      <CardContent>
        <Typography
          sx={{ fontSize: 20, fontWeight: "bold" }}
          color="text.primary"
          vairant="h4"
        >
          RYB ADC Raw Values
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginTop: 3,
          }}
        >
          <Typography sx={{ fontSize: 18 }} variant="h6">
            R : {rValue} ({firstDigit})
          </Typography>
          <Typography sx={{ fontSize: 18 }} variant="h6">
            Y : {yValue} ({secondDigit})
          </Typography>
          <Typography sx={{ fontSize: 18 }} variant="h6">
            B : {bValue} ({thirdDigit})
          </Typography>
          <Typography sx={{ fontSize: 18 }} variant="h6">
            P : {pump}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const WsCard = ({ deviceId, status }) => {
  return (
    <Card
      sx={{ minWidth: 600, borderRadius: 2, marginBottom: 3 }}
      variant="outlined"
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 20, fontWeight: "bold" }}
          color="text.primary"
          vairant="h4"
        >
          Device Information
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 3,
          }}
        >
          <Typography sx={{ fontSize: 18 }} variant="h6">
            Device Id -
          </Typography>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold" }}
            variant="h6"
            color="primary"
          >
            {deviceId}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 18 }} variant="h6">
            Websocket connection -
          </Typography>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold" }}
            variant="h6"
            color="#228B22"
          >
            {status}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
const ThCard = ({
  heading,
  subheading,
  buttonText,
  onClick,
  rValue,
  yValue,
  bValue,
  pump,
}) => {
  return (
    <Card sx={{ minWidth: 275, margin: 3, borderRadius: 2 }} variant="outlined">
      <CardContent>
        <Typography
          sx={{ fontSize: 20, fontWeight: "bold" }}
          color="text.primary"
          vairant="h4"
        >
          {heading}
        </Typography>
        <Typography sx={{ fontSize: 18 }} color="text.secondary" vairant="h3">
          {subheading}
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: 3,
          }}
        >
          <Typography sx={{ fontSize: 18 }} variant="h6">
            R : {rValue}
          </Typography>
          <Typography sx={{ fontSize: 18 }} variant="h6">
            Y : {yValue}
          </Typography>
          <Typography sx={{ fontSize: 18 }} variant="h6">
            B : {bValue}
          </Typography>
          <Typography sx={{ fontSize: 18 }} variant="h6">
            P : {pump}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClick} size="small">
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};

function App() {
  const [Values, setValues] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [bgColor, setBgColor] = useState("");

  const handleExitConfigMode = () => {
    console.log("Exit");
  };

  const handleClick = (message, color) => {
    setBgColor(color);
    setSnackMessage(message);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setBgColor("");
    setSnackMessage("");
    setOpen(false);
  };

  const setLowerThreshold = () => {
    fetch("http://seed.local/api/lower-adc-calibrate", {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ state: 0 }),
    })
      .then((res) => {
        console.log("Lower Response: ", res);
        handleClick("Success- ADC Lower", "#228B22");
      })
      .catch((err) => {
        console.log("Lower Error: ", err);
        handleClick("Failed!!! ADC Lower", "#DC143C");
      });
  };
  const setUpperThreshold = () => {
    fetch("http://seed.local/api/upper-adc-calibrate", {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ state: 0 }),
    })
      .then((res) => {
        console.log("Upper Response: ", res);
        handleClick("Success- ADC Upper", "#228B22");
      })
      .catch((err) => {
        console.log("Upper Error: ", err);
        handleClick("Failed!!! ADC Upper", "#DC143C");
      });
  };

  const exitConfigMode = () => {
    fetch("http://seed.local/api/exit-config-mode", {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ state: 0 }),
    })
      .then((res) => {
        console.log("Upper Response: ", res);
        handleClick("Success- Exit", "#228B22");
      })
      .catch((err) => {
        console.log("Upper Error: ", err);
        handleClick("Exit Failed!!!", "#DC143C");
      });
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [socketUrl, setSocketUrl] = useState("ws://seed.local/ws");
  const [messageHistory, setMessageHistory] = useState([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  // useEffect(() => {
  //   if (lastMessage !== null) {
  //     setMessageHistory((prev) => prev.concat(lastMessage));
  //   }
  // }, [lastMessage]);

  const msg = lastMessage && lastMessage.data && JSON.parse(lastMessage.data);

  msg && console.log("RYB:", msg);

  const handleClickSendMessage = useCallback(
    () => sendMessage(`{"key":"value"}`),
    []
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  // const msg = messageHistory && JSON.parse(messageHistory);
  // messageHistory &&
  //   messageHistory.map((msg, i) => {
  //     console.log("MSG" + i + ":", msg.data);
  //   });

  return (
    <div className="App">
      {/* <div>
        <button
          onClick={handleClickSendMessage}
          disabled={readyState !== ReadyState.OPEN}
        >
          Click Me to send 'Hello'
        </button>
        <span>The WebSocket is currently {connectionStatus}</span>
        {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
      </div> */}
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        <WsCard status={connectionStatus} deviceId={msg && msg.deviceId} />
        <AdcCard
          rValue={msg && msg.r_phase}
          yValue={msg && msg.y_phase}
          bValue={msg && msg.b_phase}
          pump={msg && msg.pump}
          ryb={msg && msg.ryb}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <ThCard
            heading="Upper Threshold"
            subheading="(Low Voltage)"
            buttonText="Set Threshold"
            rValue={msg && msg.rut}
            yValue={msg && msg.yut}
            bValue={msg && msg.but}
            pump={msg && msg.put}
            onClick={() => {
              console.log("pressed 1");
              setUpperThreshold();
            }}
          />
          <ThCard
            heading="Lower Threshold"
            subheading="(High Voltage)"
            buttonText="Set Threshold"
            rValue={msg && msg.rlt}
            yValue={msg && msg.ylt}
            bValue={msg && msg.blt}
            pump={msg && msg.plt}
            onClick={() => {
              console.log("pressed 1");
              setLowerThreshold();
            }}
          />
        </Box>
        <Box sx={{ minWidth: 275, margin: 2 }}>
          <Button
            sx={{ color: "white", borderColor: "white" }}
            variant="outlined"
            endIcon={<LogoutIcon />}
            onClick={() => {
              exitConfigMode();
            }}
          >
            Exit Config Mode
          </Button>
        </Box>
      </header>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackMessage}
        action={action}
        key="bottomright"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ContentProps={{
          sx: {
            background: bgColor,
          },
        }}
      />
    </div>
  );
}

export default App;
